.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  position: relative;
}

.modal-title {
  color: white;
  font-size: 33px;
  font-weight: bold;
  text-align: center;
  margin-top: 50px;
}

.modal-close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
  position: absolute;
  top: 50px;
  right: 20px;
}

.modal-close-button:hover {
  color: rgb(52, 5, 192);
}

.modal-content {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

/* @media screen and (min-width: 640px) {
  .modal-close-button {
    position: absolute;
  }
} */
.king{
  background-color: rgba(234, 237, 240, 0.792);
}

