::-webkit-scrollbar {
  width: 5px;
  border-radius: 12px;
}

::-webkit-scrollbar-track {
  background: black;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  background: 1px rgb(7, 21, 97);
}

li.suggestion-item.selected {
  background-color: rgb(73, 96, 229);
  color: #fff;
}

.carousel-container .control-prev {
  background-color: red;
}


.carousel-container .custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.carousel-container .custom-arrow.prev {
  left: 0;
  cursor: pointer;
}

.carousel-container .custom-arrow.next {
  right: 0;
  cursor: pointer;
}

.carousal-image {
  transition: transform 0.3s;
}

.center-slide {
  transform: scale(1.2);
  box-shadow: 2px 2px 20px rgba(18, 34, 207, 0.5);
}

.carousal-image:not(.center-slide) {
  transform: scale(0.9);
  opacity: 0.8;
}




/* .swiperimages img {
  width: 50px;
  height: 50px;
} */