.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-container {
  width: 480px;
}

@media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}

.ribbon {
  --f: 1px;
  --r: 15px;
  --t: 5px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 2px 8px var(--f) calc(10px + var(--r));
  clip-path: polygon(0 0,
      100% 0,
      100% calc(100% - var(--f)),
      calc(100% - var(--f)) 100%,
      calc(100% - var(--f)) calc(100% - var(--f)),
      0 calc(100% - var(--f)),
      var(--r) calc(50% - var(--f) / 2));

}

.ribbon-series {
  background-color: red;
  color: white;
  text-transform: capitalize;
}

.ribbon-movie {
  background-color: yellow;
  color: black;
  text-transform: capitalize;

}