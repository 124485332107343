body{
  overflow-x: hidden;
}
.card-container {
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.card-container:hover {
  transform: scale(1.1);
}
@media (max-width: 768px) {
  .card-container:hover {
    transform: none;
  }
}
